.layer-wrapper {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  bottom: 10px;
  left: 10px;
  z-index: 1000;
}

.layer-button {
  position: relative;
  cursor: pointer;
  width: 40px;
  height: 40px;
  transition: 0.3s;
  margin: 7px 0;
  border: 1px solid #ddd;
  border-radius: 20px;
  opacity: 1;
}

.layer-button:hover {
  transform: scale(1.1);
}

.layer-hidden {
  transform: scale(0);
  transform-origin: bottom;
}

.layer-popup {
  position: absolute;
  top: 10px;
  left: 45px;
  z-index: 1000;
}

.selected-layer {
  background: var(--vsx-color-secondary, #646cff);
}
